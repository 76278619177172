import { ConstrainMode, DetailsList, DetailsListLayoutMode, FontIcon, IColumn, IPersonaProps, Label, Persona, PersonaSize, SelectionMode, Stack, Text, Selection, IObjectWithKey, Spinner, SpinnerSize } from "@fluentui/react";
import { Editor } from "@tinymce/tinymce-react";
import { FC, useContext, useEffect, useState } from "react";
import { DraftFeedback } from "../../Models/Reviews/DraftFeedback";
import UserProfile from "../../Models/UserProfile";
import { ReviewWorkItem } from "../../Models/WorkItems/ReviewWorkItem";
import { CountdownTimer } from "../Common/CountdownTimer";
import { store } from "../Common/GlobalStateProvider";
import HeadingComponent from "../Common/HeadingComponent";
import { SpinnerPrimaryButton } from "../Common/SpinnerPrimaryButton";

export interface IReviewerDraftTasksAndBugsComponent {
    workItems: ReviewWorkItem[],
    reviewerProfiles: UserProfile[],
    onTasksOrBugsSelected?: (selectedTasksAndBugs?: ReviewWorkItem[]) => void,
    onTaskOrBugEditClick?: (editTasksAndBugs?: ReviewWorkItem) => void,
    onTaskOrBugDeleteClick?: (deleteTaskAndBug?: ReviewWorkItem) => void,
}

export const ReviewerDraftTasksAndBugsComponent: FC<IReviewerDraftTasksAndBugsComponent> = (props: IReviewerDraftTasksAndBugsComponent): JSX.Element => {
    const stackTokens = { childrenGap: 10, };
    var { appState, appdispatch } = useContext(store);
    const [reviewerPersonas, reviewerPersonasSet] = useState<IPersonaProps[]>([]);
    const [workItemsColumns, workItemsColumnsSet] = useState<IColumn[]>([
        {
            key: 'TaskType',
            name: 'Type',
            minWidth: 50
        },
        {
            key: 'Title',
            name: 'Title',
            minWidth: 100
        },
        {
            key: 'Description',
            name: 'Description',
            minWidth: 300
        },
        {
            key: 'RiskRating',
            name: 'Risk Rating',
            minWidth: 80
        },
        {
            key: 'WeaknessType',
            name: 'Weakness Type',
            minWidth: 150,
        },
        {
            key: 'CreatedBy',
            name: 'Created By',
            minWidth: 100
        },
        {
            key: 'QuickActions',
            name: 'Actions ',
            minWidth: 100
        },
    ]);

    const [draftWorkItems, draftWorkItemsSet] = useState<ReviewWorkItem[]>([]);
    function _renderItemColumn(item?: any, index?: number | undefined, column?: IColumn | undefined) {
        var wkItem = item as ReviewWorkItem;
        var cPersonas = reviewerPersonas.filter(p => p.id == wkItem.createdBy);
        var mPersonas = reviewerPersonas.filter(p => p.id == wkItem.modifiedBy);
        let createdByPersona: IPersonaProps | undefined = undefined;
        let modifiedByPersona: IPersonaProps | undefined = undefined;
        if (cPersonas && cPersonas.length > 0) {
            createdByPersona = cPersonas[0];
        }
        if (mPersonas && mPersonas.length > 0) {
            modifiedByPersona = mPersonas[0];
        }
        if (column && wkItem) {
            switch (column!.key) {
                case 'TaskType':
                    if (wkItem.type == "Bug") {
                        return <FontIcon iconName="BugSolid" color="Red" style={{ color: 'rgb(204, 41, 61)' }} />
                    }
                    return <FontIcon iconName="TaskSolid" color="Blue" style={{ color: 'rgb(0, 122, 204)' }} />;
                case 'Title':
                    return <Text title={wkItem.title}>{wkItem.title}</Text>;
                case 'Description':
                    return <Editor
                        init={{
                            skin: false,
                            content_css: false,
                            height: 50,
                            menubar: false,
                            statusbar: false,
                            toolbar: false,
                        }}
                        value={wkItem.description}
                        disabled={true} />
                //return <Text title={wkItem.description}>{wkItem.description}</Text>;
                case 'RiskRating':
                    return <Text>{wkItem.riskRating}</Text>;
                case 'WeaknessType':
                    return <Text title={wkItem.weaknessType}>{wkItem.weaknessType}</Text>;
                case 'CreatedBy':
                    return <div>
                        {createdByPersona &&
                            <Stack>
                                <Persona
                                    {...createdByPersona}
                                    key={"dtb_" + createdByPersona.key}
                                    title={createdByPersona.text}
                                    hidePersonaDetails={true}
                                    size={PersonaSize.size32}
                                />
                                {/* <Text style={{ margin: 5 }}>{revPersona.text}</Text> */}
                            </Stack>
                        }
                    </div>
                case 'ModifiedBy':
                    return <div>
                        {modifiedByPersona &&
                            <Stack>
                                <Persona
                                    {...modifiedByPersona}
                                    key={"dtb_" + modifiedByPersona.key}
                                    title={modifiedByPersona.text}
                                    hidePersonaDetails={true}
                                    size={PersonaSize.size32}
                                />
                                {/* <Text style={{ margin: 5 }}>{revPersona.text}</Text> */}
                            </Stack>
                        }
                    </div>
                case 'QuickActions':
                    return <div>
                        {
                            !wkItem.taskId &&
                            <Stack horizontal tokens={stackTokens}>
                                {wkItem.createdBy === appState.userProfile?.userPrincipalName ? <FontIcon iconName="Edit" style={{ fontSize: 14, color: "blue", cursor: 'pointer' }} title="Edit"
                                    onClick={(e) => {
                                        onEditClicked(wkItem);
                                        e.stopPropagation();
                                    }} />
                                    : <FontIcon iconName="Copy" style={{ fontSize: 14, color: "blue", cursor: 'pointer' }} title="Clone and make changes"
                                        onClick={(e) => {
                                            onEditClicked(wkItem);
                                            e.stopPropagation();
                                        }} />
                                }
                                {wkItem.createdBy === appState.userProfile?.userPrincipalName && !wkItem.isDeleteInProcess &&
                                    <FontIcon iconName="Delete" style={{ fontSize: 14, color: "blue", cursor: 'pointer' }} title="Delete"
                                        onClick={(e) => {
                                            onDeleteClicked(wkItem);
                                            e.stopPropagation();
                                        }} />
                                }
                                {
                                    wkItem.createdBy === appState.userProfile?.userPrincipalName && wkItem.isDeleteInProcess &&
                                    <Spinner title="deleting"  size={SpinnerSize.xSmall}/>
                                }
                            </Stack>
                        }
                    </div>;
                default:
                    return <span></span>;
            }
        }

        return <span></span>;
    }

    useEffect(() => {
        console.log('props invoked..')
        if (props.workItems && props.workItems.length > 0) {
            draftWorkItemsSet(props.workItems);

            if (props.reviewerProfiles && props.reviewerProfiles.length > 0) {
                var tpersonaProps: IPersonaProps[] = [];
                props.reviewerProfiles.forEach(profile => {
                    let reviewProf: IPersonaProps = {
                        id: profile.userPrincipalName,
                        text: profile.displayName,
                        imageUrl: profile.photo
                    }
                    tpersonaProps.push(reviewProf);
                });

                reviewerPersonasSet(tpersonaProps);
            }
        }
        else {
            draftWorkItemsSet([]);
        }
    }, [props]);

    const onEditClicked = (wkItem: ReviewWorkItem) => {
        if (props.onTaskOrBugEditClick) {
            props.onTaskOrBugEditClick(wkItem);
        }
    }
    const onDeleteClicked = (wkItem: ReviewWorkItem) => {
        var remainingTaksOrBugs = draftWorkItems.filter(x => x.referenceId !== wkItem.referenceId);
        draftWorkItemsSet(remainingTaksOrBugs)
        if (props.onTaskOrBugDeleteClick) {
            props.onTaskOrBugDeleteClick(wkItem);
        }
    }

    const [selectedItems, selectedItemsSet] = useState<IObjectWithKey[] | undefined>(undefined)
    useEffect(() => {
        // Do something with the selected item
        if (props.onTasksOrBugsSelected) {
            if (selectedItems && selectedItems.length > 0) {

                props.onTasksOrBugsSelected(selectedItems as ReviewWorkItem[]);
            }
            else {
                props.onTasksOrBugsSelected([]);
            }
        }
    }, [selectedItems])
    const selection = new Selection({
        onSelectionChanged: () => {
            let selectedItemss = selection.getSelection();
            selectedItemsSet(selectedItemss);
        }
    })

    return (
        <DetailsList
            items={draftWorkItems}
            compact={true}
            selection={selection}
            columns={workItemsColumns}
            onRenderItemColumn={_renderItemColumn}
            selectionPreservedOnEmptyClick={true}
            selectionMode={SelectionMode.multiple}
            ariaLabelForSelectAllCheckbox="Select All"
            checkButtonAriaLabel="check"
            layoutMode={DetailsListLayoutMode.fixedColumns}
            constrainMode={ConstrainMode.horizontalConstrained}
        />
    );
}