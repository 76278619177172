import { FC, Fragment, ReactNode, useContext, useEffect, useState } from "react";
import { Router } from "react-router-dom";
import { store } from "./Common/GlobalStateProvider";
import SideBarMenuItemComponent from "./Common/SideBarMenuItemComponent";
import SideBarComponent from "./Menubars/SideBarComponent";
import TopBarComponent from "./Menubars/TopBarComponent";
import ErrorBoundary from '../Pages/Errors/ErrorBoundary';

export const Layout: FC<ReactNode> = ({ children }): JSX.Element => {
    const { appState, appdispatch } = useContext(store);
    const [isAdmin, isAdminSet] = useState<boolean>(false);
    const [isReviewer, isReviewerSet] = useState<boolean>(false);
    const [isAuditor, isAuditorSet] = useState<boolean>(false);
    const [isReviewReader, isReviewReaderSet] = useState<boolean>(false);
    const [isServiceReader, isServiceReaderSet] = useState<boolean>(false);
    useEffect(() => {
        if (appState.initStateLoaded) {
            if (appState.userMetadata?.isAdmin) {
                isAdminSet(true);
            }

            if (appState.userMetadata?.isReviewer) {
                isReviewerSet(true);
            }

            if (appState.userMetadata?.isAuditor) {
                isAuditorSet(true);
            }
            if (appState.userMetadata?.isServiceReader) {
                isServiceReaderSet(true);
            }

            if (appState.userMetadata?.isReviewReader) {
                isReviewReaderSet(true);
            }
        }
    }, []);


    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row" style={{ marginTop: 48, marginBottom: 48, marginRight: 60, marginLeft: 60 }}>
                    <ErrorBoundary>
                        <div className="col">
                            <div className="container-md">
                                {children}
                            </div>
                        </div>
                    </ErrorBoundary>
                </div>
                <SideBarComponent IsAdmin={isAdmin} IsReviewer={isReviewer} IsAuditor={isAuditor} IsReviewReader={isReviewReader} isServiceReader = {isServiceReader}/>
                <TopBarComponent />
            </div>
        </Fragment>
    );
}