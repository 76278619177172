import { FC, useEffect, useState } from "react";
import { ReviewerNotes } from "../../Models/Reviews/ReviewerNotes";
import UserService from "../../Services/UserService";
import UserProfile from "../../Models/UserProfile";
import { IPersonaProps, Label, Persona, PersonaSize, Stack, Text } from "@fluentui/react";
import { Editor } from "@tinymce/tinymce-react";
import { isEmptyOrSpaces } from "../../Utilities/textUtilities";

interface IInternalNotesComponent {
    reviewerNotes?: ReviewerNotes[],
    userProfiles?: UserProfile[],
}
export const InternalNotesComponent: FC<IInternalNotesComponent> = (props: IInternalNotesComponent): JSX.Element => {
    const stackTokens = { childrenGap: 10, };
    const [reviewerPersonas, reviewerPersonasSet] = useState<IPersonaProps[]>([]);
    useEffect(() => {
        if (props.userProfiles && props.userProfiles.length > 0) {
            var tpersonaProps: IPersonaProps[] = [];
            props.userProfiles.forEach(profile => {
                let reviewProf: IPersonaProps = {
                    id: profile.userPrincipalName,
                    text: profile.displayName,
                    imageUrl: profile.photo
                }
                tpersonaProps.push(reviewProf);
            });

            reviewerPersonasSet(tpersonaProps);
        }
    }, [props]);

    return (<div className="row" >
        <div className="row" style={{ marginTop: 10, marginBottom: 10 }}>
            {
                props.reviewerNotes && props.reviewerNotes.length > 0 &&
                <div className="col" >
                   <Stack horizontal tokens={stackTokens}>
                    <Label>Internal Notes</Label>
                    <Text variant="xSmall" style={{marginTop: 10}}>(visible only to reviewers)</Text>
                    </Stack>
                </div>
            }

        </div>
        {
            props.reviewerNotes?.map(x => {
                return <div className="row" style={{ marginBottom: 10 }}>
                    <div className="col-4">
                        {
                            reviewerPersonas.filter(p => p.id == x.upn)?.map(personProps =>
                                <div className="row" style={{ marginTop: 30 }} key={"pin_"+ crypto.randomUUID() + personProps.key}>
                                        <Stack>
                                            <Persona
                                                className="mx-auto"
                                                {...personProps}
                                                key={"p_"+ crypto.randomUUID() + personProps.key}
                                                title={isEmptyOrSpaces(personProps.text) ? personProps.id : personProps.text}
                                                hidePersonaDetails={true}
                                                size={PersonaSize.size32}
                                            />
                                            <Text className="mx-auto">{personProps.text}</Text>
                                        </Stack>
                                </div>
                            )
                        }
                    </div>
                    <div className="col-8">
                    <Editor
                        init={{
                            skin: false,
                            content_css: false,
                            height: 150,
                            menubar: false,
                            statusbar: false,
                            toolbar: false,
                        }}
                        value={x.notes}
                        disabled={true} />
                    </div>
                </div>
            })
        }
    </div >);
}